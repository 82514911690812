const mapSectorsToString = (sector:number) : string => {
  switch (sector) {
    case 1:
    case 2:
    case 3:
    case 7:
    case 9:
    case 18:
    case 19:
    case 20:
    case 37:
    case 38:
      return 'cleaning';
    case 4:
    case 5:
    case 13:
    case 41:
      return 'carwashdetail';
    default:
      return 'general';
  }
};

const mapThemesToString = (theme:string) : string => {
  switch (theme) {
    case 'b0fe03ee-ec6d-4c85-b300-57db4e721965':
      return 'ace-premium';
    case '6d697ad9-a7cb-4d04-8449-0b9695d52e29':
      return 'ace-advanced';
    case '509bdb13-65fc-4982-8c53-f330934b4b87':
      return 'accelerator-premium';
    case 'df42ab1d-d3b6-4de0-b531-c7d42757d5f4':
      return 'accelerator-advanced';
    case 'd4c2df60-c5bc-465b-9bbc-48ef6921037c':
      return 'triumph-premium';
    case '9e8b73bf-c174-4147-acb7-e3700d172795':
      return 'triumph-advanced';
    default:
      return 'general';
  }
};

const isEmptyObj = (obj:any) : boolean => {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
};

export {
  mapSectorsToString,
  mapThemesToString,
  isEmptyObj,
};
